import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, withKeys as _withKeys, vModelText as _vModelText, withDirectives as _withDirectives, vShow as _vShow, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "\n      bg-white\n      flex flex-col\n      text-12px xxl:text-14px text-black-lp-300\n      overflow-hidden\n      h-screen\n      relative\n      w-full\n    " }
const _hoisted_2 = { class: "grid gap-6 grid-cols-5" }
const _hoisted_3 = { class: "grid gap-6 grid-cols-5" }
const _hoisted_4 = { class: "w-1/4" }
const _hoisted_5 = { class: "grid gap-6 grid-cols-12" }
const _hoisted_6 = { class: "grid gap-6 grid-cols-5" }
const _hoisted_7 = { class: "grid gap-6 grid-cols-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Select = _resolveComponent("Select")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_SelectSearch = _resolveComponent("SelectSearch")!
  const _component_lp_input = _resolveComponent("lp-input")!
  const _component_UploadImageWide = _resolveComponent("UploadImageWide")!
  const _component_button_fill = _resolveComponent("button-fill")!
  const _component_DetailLayout = _resolveComponent("DetailLayout")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_DetailLayout, {
      onBack: _ctx.goBack,
      title: "Buat Transaksi",
      "vertical-separator": false,
      fullPage: ""
    }, {
      data: _withCtx(() => [
        _createVNode("div", _hoisted_2, [
          _createVNode(_component_DataWrapper, {
            class: "col-span-2",
            label: "Tipe Transaksi"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Select, {
                onOpenSelect: _ctx.onOpenSelectTransactionType,
                onCloseSelect: _ctx.onCloseSelectTransactionType,
                isOpenMenu: _ctx.isOpenTransactionType,
                value: _ctx.form.transactionType,
                onChooseSelect: _ctx.onSelectTransactionType,
                dataSelect: _ctx.transactionTypeList,
                class: "w-full whitespace-no-wrap my-1.5"
              }, null, 8, ["onOpenSelect", "onCloseSelect", "isOpenMenu", "value", "onChooseSelect", "dataSelect"])
            ]),
            _: 1
          }),
          _createVNode(_component_DataWrapper, {
            class: "col-span-2",
            label: "Tipe Akun"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Select, {
                onOpenSelect: _ctx.onOpenSelectAccountType,
                onCloseSelect: _ctx.onCloseSelectAccountType,
                isOpenMenu: _ctx.isOpenAccountType,
                value: _ctx.form.accountType,
                onChooseSelect: _ctx.onSelectAccountType,
                dataSelect: _ctx.accountTypeList,
                class: "w-full whitespace-no-wrap my-1.5",
                isDisabled: _ctx.isAccountTypeDisabled
              }, null, 8, ["onOpenSelect", "onCloseSelect", "isOpenMenu", "value", "onChooseSelect", "dataSelect", "isDisabled"])
            ]),
            _: 1
          })
        ]),
        _createVNode("div", _hoisted_3, [
          _createVNode(_component_DataWrapper, {
            class: "col-span-2",
            label: "Nama Client/Partner"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_SelectSearch, {
                class: "my-1.5",
                onFilter: _ctx.fetchClientPartner,
                isLoading: _ctx.loadingClientPartner,
                options: _ctx.partnerClientData,
                keyName: "name",
                keyValue: "id",
                placeholder: "Pilih client/partner",
                modelValue: _ctx.form.client,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.form.client = $event)),
                onChange: _ctx.changePartnerData,
                isDisabled: _ctx.partnerDisabled
              }, null, 8, ["onFilter", "isLoading", "options", "modelValue", "onChange", "isDisabled"])
            ]),
            _: 1
          }),
          _createVNode(_component_DataWrapper, {
            class: "col-span-2",
            label: "Kode Client/Partner"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_lp_input, {
                placeholder: "XXXXX",
                modelValue: _ctx.form.partnerCode,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.form.partnerCode = $event)),
                disabled: ""
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _createVNode("div", _hoisted_4, [
          _createVNode(_component_DataWrapper, {
            label: "Jumlah",
            class: "w-full"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_lp_input, {
                modelValue: _ctx.form.count,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.form.count = $event)),
                prefix: _ctx.prefixCurrency,
                onChange: _ctx.validateCount,
                error: _ctx.error.count,
                errorCaption: _ctx.errorCaptionCount,
                format: _ctx.formatCount,
                onKeydown: _cache[4] || (_cache[4] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["space"])),
                placeholder: "e.g 20.000"
              }, null, 8, ["modelValue", "prefix", "onChange", "error", "errorCaption", "format"])
            ]),
            _: 1
          })
        ]),
        _createVNode("div", _hoisted_5, [
          _createVNode(_component_DataWrapper, {
            class: "col-span-10",
            label: "Bukti Transaksi",
            optional: true
          }, {
            default: _withCtx(() => [
              _createVNode(_component_UploadImageWide, {
                modelValue: _ctx.form.archiveFile,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.form.archiveFile = $event)),
                isClear: _ctx.isClearFile,
                "onUpdate:isClear": _cache[6] || (_cache[6] = ($event: any) => (_ctx.isClearFile = $event)),
                caption: ".jpg/.png maks. 2 MB",
                maxSize: 5
              }, null, 8, ["modelValue", "isClear"])
            ]),
            _: 1
          })
        ]),
        _createVNode("div", _hoisted_6, [
          _createVNode(_component_DataWrapper, {
            class: "col-span-10",
            label: "Catatan",
            optional: true
          }, {
            default: _withCtx(() => [
              _withDirectives(_createVNode("textarea", {
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_ctx.form.notes = $event)),
                placeholder: "Masukan catatan",
                cols: "124",
                rows: "3",
                class: 
                `py-1.5 xxl:py-3 px-4 rounded border focus:outline-none focus:shadow my-1
            text-black-lp-300`
              
              }, null, 2), [
                [_vModelText, _ctx.form.notes]
              ])
            ]),
            _: 1
          })
        ]),
        _createVNode("div", _hoisted_7, [
          _createVNode(_component_button_fill, {
            title: "Buat",
            class: "px-4 bg-red-lp-300",
            custom: "w-1/2",
            isDisabled: !_ctx.isFormValid,
            onClick: _ctx.onOpenSave
          }, null, 8, ["isDisabled", "onClick"])
        ])
      ]),
      _: 1
    }, 8, ["onBack"]),
    _withDirectives(_createVNode(_component_Modal, {
      onSubmit: _ctx.onSubmit,
      onClose: _ctx.onCloseSave,
      title: "Konfirmasi Pembuatan",
      message: 
        `Pastikan kembali, seluruh detail transaksi sudah benar dan sesuai.`
      ,
      image: "are-you-sure",
      textSuccess: "Buat",
      textCancel: "Kembali",
      class: "px-8",
      isLoading: _ctx.loadingSubmit
    }, null, 8, ["onSubmit", "onClose", "message", "isLoading"]), [
      [_vShow, _ctx.isOpenSaveModal]
    ]),
    _withDirectives(_createVNode(_component_Modal, {
      onSubmit: _ctx.onCloseSuccess,
      title: "Pembuatan Berhasil !",
      message: `Transaksi “${_ctx.transactionTypeName}” berhasil dibuat. `,
      image: "image-modal-success",
      textSuccess: "OK",
      class: "px-8"
    }, null, 8, ["onSubmit", "message"]), [
      [_vShow, _ctx.openSuccess]
    ]),
    _withDirectives(_createVNode(_component_Modal, {
      onSubmit: _ctx.handlerClose,
      onClose: _ctx.handlerCancelClose,
      title: "Yakin Keluar Halaman ?",
      message: "Anda belum selesai mengisi form. Permintaan tidak akan diproses jika Anda keluar dari halaman ini.",
      image: "are-you-sure",
      textSuccess: "Keluar",
      textCancel: "Kembali",
      class: "px-8"
    }, null, 8, ["onSubmit", "onClose"]), [
      [_vShow, _ctx.isAddTerminated]
    ])
  ]))
}