
import { Options, Vue } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import debounce from "lodash/debounce";
import { formatInputMoneyWithDecimal } from "@/app/infrastructures/misc/Utils";
import UploadImageWide from "@/app/ui/components/upload-image-wide/index.vue";
import { ClientController } from "@/app/ui/controllers/ClientController";
import { ManualDeductAddSaldoController } from "@/app/ui/controllers/ManualDeductAddSaldoController";
import { ClientPartnerData } from "@/domain/entities/Client";

@Options({
  components: {
    DetailLayout,
    UploadImageWide
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (!this.openSuccess) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      ManualDeductAddSaldoController.setOpenSuccess(false);
      next();
    }
  }
})
export default class Add extends Vue {
  isAddTerminated = false;
  newPath = "";
  answer = false;
  onOpenClosePage(to: any) {
    this.isAddTerminated = true;
    this.newPath = to.path;
  }

  handlerClose() {
    this.isAddTerminated = false;
    this.answer = true;
    this.$router.push(this.newPath);
  }

  handlerCancelClose() {
    this.answer = false;
    this.isAddTerminated = false;
  }

  goBack() {
    this.$router.push("/finance/manual-deduct");
  }

  isClearFile = false;
  form: any = {
    accountType: "",
    client: "",
    partnerCode: "",
    transactionType: "",
    archiveFile: "",
    notes: "",
    count: "",
    currency: ""
  };

  errorCaptionCount = "";
  error = {
    count: false
  };

  get isFormValid() {
    let result = false;
    if (
      this.form.accountType &&
      this.form.client &&
      this.form.partnerCode &&
      this.form.transactionType &&
      this.form.count &&
      !this.error.count
    ) {
      result = true;
    }
    return result;
  }

  get isOpenSaveModal() {
    return ManualDeductAddSaldoController.openModal;
  }

  onOpenSave() {
    ManualDeductAddSaldoController.setOpenModal(true);
  }

  onCloseSave() {
    ManualDeductAddSaldoController.setOpenModal(false);
  }

  onSubmit() {
    ManualDeductAddSaldoController.createDeductSaldo({
      actorType:
        this.form.accountType === "partner" ? "pos" : this.form.accountType,
      actorId: this.form.client.id,
      adjustmentType: this.form.transactionType,
      amount: this.form.count.split(".").join(""),
      notes: this.form.notes,
      proofFile: this.form.archiveFile,
      currency: this.form.currency
    });
  }

  get loadingSubmit() {
    return ManualDeductAddSaldoController.isLoading;
  }

  get openSuccess() {
    return ManualDeductAddSaldoController.openSuccess;
  }

  onCloseSuccess() {
    this.goBack();
  }

  handleErrorClose() {
    ManualDeductAddSaldoController.setError(false);
  }

  // error handle
  validateCount(value: string) {
    const newValue = value.replace(".", "").replace(",", ".");
    if (Number(newValue) <= 0) {
      this.error.count = true;
      this.errorCaptionCount = "Jumlah tidak boleh nol/kosong";
    } else {
      this.error.count = false;
    }
  }

  formatCount(value: any) {
    return formatInputMoneyWithDecimal(value);
  }

  partnerDisabled = true;
  clientPartnerStatus = "partner";

  // client
  fetchClientPartner = debounce((search: string) => {
    ClientController._onGetListPartner({
      page: 1,
      limit: 100,
      search: search,
      partnerType: this.clientPartnerStatus === "partner" ? "pos" : "",
      type: this.clientPartnerStatus === "partner" ? "partner" : "client",
      isParent: this.clientPartnerStatus != "partner"
    });
  }, 250);

  get loadingClientPartner() {
    return ClientController.isLoading;
  }

  get partnerClientData() {
    return ClientController.clientPartnerData.map(e => ({
      id: e.id,
      name: `${e.name}`,
      code: e.code,
      country: e.country
    }));
  }

  changePartnerData(item: ClientPartnerData) {
    this.form.partnerCode = item.code;
    this.form.currency = item.country?.toUpperCase() === "MY" ? "MYR" : "IDR";
  }

  // account type
  get isAccountTypeDisabled() {
    return (
      this.form.transactionType === "stt_deduct_adjustment_saldo_cod" ||
      this.form.transactionType === "stt_add_adjustment_saldo_cod" ||
      this.form.transactionType === ""
    );
  }
  accountTypeList = [
    {
      name: "Pilih client/partner",
      value: ""
    },
    {
      name: "Partner",
      value: "partner"
    },
    {
      name: "Client",
      value: "client"
    }
  ];

  isOpenAccountType = false;
  onOpenSelectAccountType() {
    this.isOpenAccountType = true;
  }

  onCloseSelectAccountType() {
    this.isOpenAccountType = false;
  }

  onSelectAccountType(key: string, value: string) {
    this.onCloseSelectAccountType();
    this.form.accountType = value;
    this.partnerDisabled = false;
    this.form.client = "";
    this.form.partnerCode = "";

    this.fetchClientPartner("");

    if (value === "partner") {
      this.clientPartnerStatus = "partner";
    } else {
      this.clientPartnerStatus = "client";
    }
  }

  // transaction type
  transactionTypeList = [
    {
      name: "Pilih",
      value: ""
    },
    {
      name: "Rekonsiliasi Pengurangan Saldo",
      value: "stt_deduct_adjustment_saldo"
    },
    {
      name: "Rekonsiliasi Penambahan Saldo",
      value: "stt_add_adjustment_saldo"
    },
    {
      name: "Rekonsiliasi Pengurangan Penghasilan COD",
      value: "stt_deduct_adjustment_saldo_cod"
    },
    {
      name: "Rekonsiliasi Penambahan Penghasilan COD",
      value: "stt_add_adjustment_saldo_cod"
    }
  ];

  isOpenTransactionType = false;
  onOpenSelectTransactionType() {
    this.isOpenTransactionType = true;
  }

  onCloseSelectTransactionType() {
    this.isOpenTransactionType = false;
  }

  transactionTypeName: any = "";
  onSelectTransactionType(key: string, value: string) {
    const theType: any = this.transactionTypeList.find(
      (item: any) => item.value === value
    );
    this.transactionTypeName = theType.name;
    this.onCloseSelectTransactionType();
    this.form.transactionType = value;
    if (
      value === "stt_deduct_adjustment_saldo_cod" ||
      value === "stt_add_adjustment_saldo_cod"
    ) {
      this.onSelectAccountType(
        this.accountTypeList[2].name,
        this.accountTypeList[2].value
      );
    }
  }

  get prefixCurrency() {
    return this.form.currency === "MYR" ? "RM" : "IDR";
  }
}
