
import { ellipsisString } from "@/app/infrastructures/misc/Utils";
import { Options, Vue, prop } from "vue-class-component";
import ModalShowImage from "@/app/ui/components/modal-show-image/index.vue";

class Props {
  modelValue = prop<any>({
    required: true
  });
  title = prop<string>({
    default: "Klik Untuk Upload",
    type: String
  });
  buttonCaption = prop<string>({
    default: "Ubah File",
    type: String
  });
  caption = prop<string>({
    default: "",
    type: String
  });
  isClear = prop<boolean>({
    default: false,
    type: Boolean
  });
  maxSize = prop<number>({
    default: 256, // size in MB
    type: Number
  });
  isWarning = prop<boolean>({
    default: false,
    type: Boolean
  });
  warningCaption = prop<string>({
    default: "",
    type: String
  });
}

@Options({
  components: {
    ModalShowImage
  }
})
export default class UploadFile extends Vue.with(Props) {
  isDataValidate = false;
  isDragFile = false;
  fileOriginal = "";
  isType = "";

  $refs!: {
    file: HTMLFormElement;
  };

  setDragAndDrop(e: any, value: boolean) {
    this.isDragFile = value;
    e.preventDefault();
  }

  onUploadImage(file: any) {
    const type = file.name.split(".").pop();
    this.isType = type;
    if (type === "jpg" || type === "png" || type === "jpeg") {
      if (file.size > this.maxSize * 1024 * 1024) this.setError();
      else {
        this.fileOriginal = URL.createObjectURL(file);
        this.$emit("update:modelValue", file);
        this.isDataValidate = false;
        this.isDragFile = false;
        this.$refs.file.value = null;
      }
    } else this.setDefault();
  }

  onSubmitClick(e: any) {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      this.onUploadImage(file);
    } else this.setDefault();
  }

  // Drag File
  onSubmitDrag(e: any) {
    if (e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer && e.dataTransfer.files[0];
      this.onUploadImage(file);
    } else this.setDefault();
  }

  setDefault() {
    this.$emit("update:modelValue", "");
    this.$refs.file.value = "";
    this.isDataValidate = true;
    this.isDragFile = false;
  }

  setError() {
    this.isDataValidate = true;
    this.isDragFile = false;
  }

  // Watch isClear changed
  updated() {
    if (this.isClear) this.setDefault();
  }

  // Ellipsis String
  ellipsisString(value: string) {
    return ellipsisString(value, 50);
  }

  // Show image
  isShow = false;
  onOpenShowImage(value: boolean) {
    this.isShow = value;
  }

  removeFile() {
    this.$emit("update:modelValue", "");
    this.$refs.file.value = null;
    this.isDragFile = false;
  }
}
